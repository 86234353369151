import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, Box } from '../ui'
import { ELoading } from '../../store/dictionaries/interfaces'
import useMount from '../hooks/useMount'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { UnregisteredLayout, RegisteredLayout } from './components'
import { getDictionaries } from '../../store/dictionaries'
import { EUserRole } from '../../constants/profile'
import style from './style.m.less'

interface IGreenTools {
    isAuth: boolean
    isUserType?: boolean
}

const GreenTools: FC<IGreenTools> = ({ isAuth, isUserType }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isOwnerType = [EUserRole.GOOwner].includes(userRole)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(userRole)

    useMount(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    return (
        <div className={style.greenToolsMain}>
            <div className={style.mainHeader}>
                <div className={style.container}>
                    <div className={style.title}>
                        {isUserType ? t('greenTools.myGreenTools') : t('greenTools.greenTools')}
                    </div>
                    {isAuth && (
                        <div className={style.buttonsBlock} >
                            {isOwnerType && (
                                <button className={style.button} onClick={() => navigate('/issue-certificate')}>
                                    {t('detailedCard.issueCerf')}
                                </button>
                            )}
                            {isAdminType && (
                                <>
                                    <button className={style.button}
                                        onClick={() => navigate('/third-party-tools-adding')}
                                    >
                                        {'Добавить сторонние инструменты'}
                                    </button>
                                    <button className={style.button}
                                        onClick={() => navigate('/third-party-tools-repayment')}
                                    >
                                        {'Погасить сторонние инструменты'}
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={style.mainWrapper}>
                {isAdminType ? (
                    <RegisteredLayout userRole={userRole} isUserType={isUserType} />
                ) : (
                    <UnregisteredLayout />
                )}
            </div>
        </div>
    )
}

export default GreenTools
