import { FC } from 'react'
import { Bar } from 'react-chartjs-2/dist'
import { roundUp } from '../../utils'

const data = (graphData, toolType) => {
    const labels = []
    const certSet = [
        {
            label: 'Сертификаты происхождения',
            data: [],
            backgroundColor: '#52D2A0',
            barThickness: 56,
        },
    ]
    const othersSet = [
        {
            label: 'Сторонние инструменты',
            data: [],
            backgroundColor: '#F4FFB8',
            barThickness: 56,
        },
    ]
    const contractsSet = [
        {
            label: 'Зеленые договоры',
            data: [],
            backgroundColor: '#EFDBFF',
            barThickness: 56,
        },
    ]
    const datasets = toolType === 'Истек срок действия' ? [...othersSet, ...certSet] : [...othersSet, ...contractsSet, ...certSet]
    graphData.forEach((item) => {
        labels.push(item.year)
        if (toolType === 'Выпущенные') {
            datasets[0].data.push(Math.round(item.extInstrumentsTotalVolume / 1000))
            datasets[1].data.push(Math.round(item.contractTotalVolume / 1000))
            datasets[2].data.push(Math.round(item.certificatesIssueVolume / 1000))
        } else if (toolType === 'Погашенные') {
            datasets[0].data.push(Math.round(item.extInstrumentsUsedVolume / 1000))
            datasets[1].data.push(Math.round(item.contractUsedVolume / 1000))
            datasets[2].data.push(Math.round(item.certificatesUsedVolume / 1000))
        } else if (toolType === 'Истек срок действия') {
            datasets[0].data.push(item?.extInstrumentsExpiredVolume ? Math.round(item.extInstrumentsExpiredVolume / 1000) : 0)
            datasets[1].data.push(item?.certificatesExpiredVolume ? Math.round(item.certificatesExpiredVolume / 1000) : 0)
        }
    })
    return {
        labels,
        datasets,
    }
}

const options = (graphData, toolType) => {
    const values_ = []
    const sums = []
    var i = 0
    graphData.forEach((item) => {
        values_.push( [] )
        var sum = 0
        if (toolType === 'Выпущенные') {
            values_[ i ].push(item.extInstrumentsTotalVolume)
            values_[ i ].push(item.contractTotalVolume)
            values_[ i ].push(item.certificatesIssueVolume)
            sum += item.extInstrumentsTotalVolume
            sum += item.contractTotalVolume
            sum += item.certificatesIssueVolume
        } else if (toolType === 'Погашенные') {
            values_[ i ].push(item.extInstrumentsUsedVolume)
            values_[ i ].push(item.contractUsedVolume)
            values_[ i ].push(item.certificatesUsedVolume)
            sum += item.extInstrumentsUsedVolume
            sum += item.contractUsedVolume
            sum += item.certificatesUsedVolume
        } else if (toolType === 'Истек срок действия') {
            values_[ i ].push(item?.certificatesExpiredVolume ? item.certificatesExpiredVolume : 0)
            values_[ i ].push(item?.extInstrumentsExpiredVolume ? item.extInstrumentsExpiredVolume : 0)
            sum += item.certificatesExpiredVolume
            sum += item.extInstrumentsExpiredVolume
        }
        sums.push(sum)
        i++
    })

    var sum = 0
    var max_sum_i = 0
    for (let i = 0; i < sums.length; i++) 
        if( sums[ i ] > sum ) {
            sum = sums[ i ]
            max_sum_i = i
        }

    const values = values_[ max_sum_i ]
    const preparedValues = values?.map((item) => Math.round(item / 1000)) || []
    const maxValue = preparedValues.length
        ? preparedValues.reduce((partialSum, value) => partialSum + value, 0)
        : 100000
    const cleanMaxValue = maxValue !== 0 ? roundUp(maxValue, 1000) : 100000
    const stepSize = Math.round(cleanMaxValue / 5)
    const max = cleanMaxValue + stepSize

    return {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15,
                    padding: 24,
                },
                reverse: true,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                },
            },
            y: {
                stacked: true,
                position: 'right',
                grid: {
                    color: '#E6F1FF',
                },
                min: 0,
                max,
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                    stepSize,
                },
            },
        },
    }
}

interface IStackedBarChart {
    toolType: string
    graphData: any
}

const StackedBarChart: FC<IStackedBarChart> = ({ toolType, graphData }) => {
    return graphData ? (
        <Bar data={data(graphData, toolType)} options={options(graphData, toolType)} />
    ) : (
        <></>
    )
}

export default StackedBarChart
