import { FC, useEffect, useState, useRef, useMemo, createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import useMount from '../hooks/useMount'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getDictionaries } from '../../store/dictionaries'
import style from './style.m.less'
import { Form, Input, Space, Spin } from 'antd'
import classnames from 'classnames'
import { Contacts, Documents, Information, Reports, Collapse, Panel, SubPanel } from './components'
import {
    Card,
    CustomModal,
    DeleteModal,
    DropdownSelector,
    FileView,
    GreyButton,
    IconButton,
    PrimaryIconButton
} from "../ui";
import {TextWrap} from "../Documents/TextWrap";
import {Edit, Export, Trash} from "../../assets/icons";
import useRequest, {RequestTypes} from "../hooks/useRequest";
import useUploadFile from "../hooks/useUploadFile";
import {EUserRole} from "../../constants/approval";
import useDownLoadFile from "../hooks/useDownloadFile";
import { DownOutlined, UpOutlined } from '@ant-design/icons'

const objAbout = { 201: 'documents.title', 202: 'information.title', 203: 'reports.title'}

const About: FC = () => {
    const refs = useRef([])

    const [noShowIcons, setNoShowIcons] = useState({})

    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isAdmin = [EUserRole.Administrator, userRole === EUserRole.SuperAdmin].includes(userRole)

    const { downloadFile, loadingDownLoad } = useDownLoadFile()

    const { t } = useTranslation()
    const options = [
        {id: 201, value: t('about.documents.title')},
        {id: 202, value: t('about.information.title')},
        {id: 203, value: t('about.reports.title')}
    ]
    // const navigate = useNavigate()
    const [form] = Form.useForm()
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const [showDocModal, setShowDocModal] = useState(false)
    const [docIdToEdit, setDocIdToEdit] = useState('')
    const [type, setType] = useState('create')

    const fileInput = useRef<HTMLInputElement>(null)
    const handleClickInput = () => {
        fileInput.current?.click()
    }

    const {
        fetch: postDocs,
        result: responsePostNews,
        isLoading: isLoadingPost,
    } = useRequest({
        link: `/api/content/v1/docs`,
        method: RequestTypes.post,
    })
    const {
        fetch: getDocs,
        result: docs,
        isLoading: docsLoading,
    } = useRequest({
        link: `/api/content/v1/docs`,
        method: RequestTypes.get,
    })
    const {
        fetch: delDoc,
        result: deleteDocResult,
        isLoading: deleteDocLoading,
    } = useRequest({
        link: `/api/content/v1/docs`,
        method: RequestTypes.delete,
    })

    // в сервис контента (6) подгружаем список из 1го файла    (редактирование)
    // is_public=1 для публичного доступа к картинтам
    const { uploadFile, uploading, files, setFiles } = useUploadFile(6, 1, 1)
    // или у нас на форме подгужен файл или файл подгруженый для редактирования
    // const isFile = files?.[0]?.id || newsToChange?.document?.id
    const isFile = files?.[0]?.id
    const file = {
        id: files?.[0]?.id,
        name: files?.[0]?.name,
        size: files?.[0]?.size,
    }

    const onFinish = async (values: any) => {
        const body = {
            ...values,
            ...(type === 'change' && { id: docIdToEdit }),
            section_id: (options.find((el) => el.value === values.section_id) || {}).id,
            document: {
                id: file.id,
            },
        }
        postDocs({
            body,
            addToLink: `/${type}`,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Сохранение прошло успешно',
            },
            onSuccess: () => {
                getDocs({
                    body: {},
                })
                setShowDocModal(false)
            },
            errorCodeMessage: true,
        })
    }

    useEffect(() => {
        getDocs({
            body: {},
        })
    }, [])

    const handleAddDocuments = () => {
        setType('create')
        setFiles([])
        form.setFieldsValue({ section_id: '', comment: '' })
        setShowDocModal(true)
    }
    const handleClickEdit = (doc: any) => {
        setFiles([
            {
                id: doc.document.id,
                name: doc.document.file_name,
                size: doc.document.file_size,
            },
        ])
        form.setFieldsValue({
            section_id: (options.find((el) => el.id == doc.section_id) || {}).value,
            comment: doc.comment,
        })
        setType('change')
        setDocIdToEdit(doc.id)
        setShowDocModal(true)
    }

    // модалка на кнопку удаления
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState('')

    const handleClickDeleteDocument = () => {
        delDoc({
            body: {},
            addToLink: `?id=${showDeleteDocumentModal}`,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Удаление прошло успешно',
            },
            onSuccess: () => {
                getDocs({
                    body: {},
                })
            },
            errorCodeMessage: true,
        })
        setShowDeleteDocumentModal('')
    }

    const sectionDocs = useMemo(() => {
        const sectionDocuments = {}
        ;(Object.entries(objAbout) || []).forEach(([key, docName]: Array<string | number>) => {
            const sectionArr = (docs || []).filter((item: any) => item.section_id == key)
            ;(sectionDocuments as any)[docName] = sectionArr.length ? sectionArr : undefined
        })
        return sectionDocuments // { 'docs': [{section_id:1,..},..], 'npa': [{section_id:2,..}..], ...}
    }, [docs])

    // ids документов, которые нажали и раскрыли описание
    const [openTextIds, setOpenTextIds] = useState({})

    useEffect(() => {
        let handler = setTimeout(() => {}, 0)
        const addFun = (event) => {
            if (!event?.target?.id) {
                // без setTimeout перестают кликаться остальные елементы, как-то связано с поит ивентером
                handler = setTimeout(() => {
                    setOpenTextIds((s: any) => ({}))
                }, 200)
            }
        }

        document.addEventListener('mouseup', addFun)
        return () => {
            // Unbind the event listener on clean up
            clearTimeout(handler) // была ошибка в консоли Can't perform a React state update on an unmounted component
            document.removeEventListener('mouseup', addFun)
        }
    }, [])

    useMount(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    return (
        <>
        <div className={style.about}>
            <div className={style.aboutScreen}>
                <div className={style.container}>
                    <h1 className={style.title}>{t('about.title')}</h1>
                    <CustomModal
                        isModalVisible={showDocModal}
                        styleWrapper={{ marginBottom: '24px' }}
                        styleButtonsBlock={{ display: 'flex' }}
                        params={{
                            title:
                                type === 'create'
                                    ? t('documents.addingDocuments')
                                    : t('documents.editingDocuments'),
                            cancelTextButton: t('documents.cancel'),
                            confirmTextButton: t('documents.confirm'),
                        }}
                        children={
                            <div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    onFinish={onFinish}
                                    name="form"
                                    className={classnames(style.form, style.formDocs)}
                                    validateTrigger={['onSubmit', 'onBlur', 'onChange']}
                                >
                                    <Form.Item
                                        label={t('documents.section')}
                                        name="section_id" // Тип 1 - Документы, 2 - Нормативные акты, 3 - Регистрация, 4 - Стандартные формы, 5 - Отчеты
                                        rules={[{ required: true, message: t('rules.required') }]}
                                    >
                                        <DropdownSelector
                                            noDefaultValue={false}
                                            options={options}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('documents.doc')}
                                        style={{ width: '436px' }}
                                        name="id"
                                    >
                                        <IconButton
                                            disabled={uploading}
                                            customIconStyle={{
                                                marginTop: '4px',
                                                marginRight: '5px',
                                            }}
                                            icon={<Export />}
                                            onClick={handleClickInput}
                                            loading={uploading}
                                        >
                                            {t(
                                                isFile
                                                    ? 'documents.changeDoc'
                                                    : 'documents.downloadDoc',
                                            )}
                                        </IconButton>
                                        {isFile && (
                                            <FileView
                                                style={{ marginTop: '16px' }}
                                                title={uploading ? '' : file?.name}
                                                size={uploading ? '' : file?.size}
                                                loading={uploading}
                                            />
                                        )}

                                        <input
                                            type="file"
                                            onChange={uploadFile}
                                            ref={fileInput}
                                            style={{ display: 'none' }}
                                        />
                                    </Form.Item>

                                    {file?.id && (
                                        <Form.Item
                                            label={t('documents.comment')}
                                            style={{ width: '436px' }}
                                            name="comment"
                                            rules={[
                                                { required: true, message: t('rules.required') },
                                            ]}
                                        >
                                            <Input.TextArea
                                                style={{
                                                    minHeight: 82,
                                                    width: '436px',
                                                    border: '1px solid #D3D5DA',
                                                    borderRadius: '8px',
                                                }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form>
                            </div>
                        }
                        onClose={() => setShowDocModal(false)}
                        closeAfterConfirm={false}
                        onConfirm={() => {
                            form.submit()
                        }}
                        confirmDisabled={
                            uploading || !isFile || form.getFieldValue('section_id') === ''
                        }
                    />
                    {isAdmin && (
                        <GreyButton
                            onClick={handleAddDocuments}
                            value={t('documents.addDocuments')}
                            customStyle={{ marginTop: '32px' }}
                        />
                    )}
                </div>
            </div>
            <div className={style.container}>
                <div className={style.text}>
                    <span>{t('about.fed_law_text')}</span>
                    <a rel="external"
                       href="http://publication.pravo.gov.ru/document/0001202308040088?index=1">{t('about.fed_law_link_text')}</a>
                    <span>{t('about.gov_decree_text')}</span>
                    <a rel="external"
                       href="http://publication.pravo.gov.ru/document/0001202312290058">{t('about.gov_decree_link_text')}</a>
                </div>
            </div>
            <div className={classnames(style.container, style.marginTop)}>
                <Spin spinning={docsLoading}>
                <Collapse defaultActiveKey={objAbout[201]} className={style.padding}>
                    {(Object.values(objAbout) || []).map((docName: any, i: number) => {
                        return (
                            <Panel
                                header={t(`about.${docName}`)}
                                key={docName}
                                className={classnames(style.padding, style.collap)}
                            >
                                <div className={classnames(style.grid, style.padding)}>
                                    {(sectionDocs as any)[docName] ? (
                                        (sectionDocs as any)[docName].map(
                                            (doc: any, j: number) => (
                                                <Card
                                                    key={doc.id}
                                                    style={{
                                                        padding: 0,
                                                        minHeight: '147px',
                                                        height: '147px',
                                                    }}
                                                >
                                                    <Card
                                                        key={doc.id}
                                                        style={{
                                                            margin: 0,
                                                            padding: '0 0 24px 0',
                                                            width: '416px',
                                                            maxWidth: '416px',
                                                            position: 'relative',
                                                            cursor: 'text',
                                                            minHeight: '147px',
                                                            zIndex: openTextIds[doc.id]
                                                                ? '1000'
                                                                : 0,
                                                            boxShadow:
                                                                '0px 4px 26px rgb(40, 97, 91, 0.05)',
                                                        }}
                                                    >
                                                        <div
                                                            id={j + i}
                                                            ref={(el) => (refs.current[j + i] = el)}
                                                            style={{
                                                                display: 'inline-flex',
                                                                width: '100%',
                                                                padding: '24px',
                                                            }}
                                                        >
                                                            <TextWrap
                                                                id={docName + j + i}
                                                                setNoShowIcons={
                                                                    setNoShowIcons
                                                                }
                                                                openTextIds={openTextIds}
                                                                doc={doc}
                                                            />

                                                            {isAdmin && (
                                                                <Space
                                                                    style={{
                                                                        marginTop: '0px',
                                                                        alignItems: 'unset',
                                                                    }}
                                                                >
                                                                    {
                                                                        <PrimaryIconButton
                                                                            customStyle={{
                                                                                marginLeft:
                                                                                    '16px',
                                                                            }}
                                                                            icon={<Edit/>}
                                                                            onClick={() =>
                                                                                handleClickEdit(
                                                                                    doc,
                                                                                )
                                                                            }
                                                                            loading={
                                                                                isLoadingPost &&
                                                                                doc.id ===
                                                                                docIdToEdit
                                                                            }
                                                                        />
                                                                    }
                                                                    {
                                                                        <PrimaryIconButton
                                                                            isCancel
                                                                            icon={<Trash/>}
                                                                            onClick={() => {
                                                                                setShowDeleteDocumentModal(
                                                                                    doc.id,
                                                                                )
                                                                            }}
                                                                            loading={
                                                                                deleteDocLoading &&
                                                                                doc.id ===
                                                                                showDeleteDocumentModal
                                                                            }
                                                                        />
                                                                    }
                                                                </Space>
                                                            )}
                                                            {!noShowIcons[docName + j + i] && (
                                                                <div
                                                                    style={{
                                                                        margin: '0 4px 0 16px',
                                                                        paddingTop: '6px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    id={docName + i}
                                                                    onClick={() => {
                                                                        setOpenTextIds((s: any) => ({
                                                                                [doc.id]: !s[doc.id],
                                                                            }),
                                                                        )
                                                                    }}>
                                                                    {openTextIds[doc.id] ? (
                                                                        <UpOutlined
                                                                            style={{
                                                                                pointerEvents:
                                                                                    'none',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <DownOutlined
                                                                            style={{
                                                                                pointerEvents:
                                                                                    'none',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <FileView
                                                            style={{margin: '0 24px'}}
                                                            fileTitleStyle={{
                                                                maxWidth: '340px',
                                                            }}
                                                            title={doc.document.file_name}
                                                            size={doc.document.file_size}
                                                            onClick={() =>
                                                                downloadFile(
                                                                    doc.document.id,
                                                                    doc.document.file_name,
                                                                )
                                                            }
                                                            loading={
                                                                loadingDownLoad?.[
                                                                    doc.document.id
                                                                    ]
                                                            }
                                                        ></FileView>
                                                    </Card>
                                                </Card>
                                            ),
                                        )
                                    ) : (
                                        <p>{t('documents.noDocs')}</p>
                                    )}
                                    <DeleteModal
                                        isModalVisible={!!showDeleteDocumentModal}
                                        headerText={t('documents.delDoc')}
                                        messageText={`${t('documents.delDocQuestion')} “${
                                            (docs || []).find(
                                                (d: any) => d.id == showDeleteDocumentModal,
                                            )?.document?.file_name || ''
                                        }” ?`}
                                        onCancel={() => setShowDeleteDocumentModal('')}
                                        onDelete={() => handleClickDeleteDocument()}
                                    />
                                </div>
                            </Panel>
                        )
                        }
                    )}
                </Collapse>
                </Spin>
            </div>
            <div className={style.container}>
                <Contacts/>
            </div>
        </div>
        </>
    )
}

export default About
