import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { DownMini } from '../../../../assets/icons'
import style from './style.m.less'

const generalRoutes = [
    { id: 1, to: 'main', title: 'header.main' },
    { id: 2, to: 'about', title: 'header.about'},
    { id: 3, to: 'documents', title: 'header.documents' },
    { id: 4, to: 'green-tools', title: 'header.greenTools', addUrls: ['certificates-in-pledge'] },
    { id: 5, to: 'qualification', title: 'header.qualification' },
    { id: 6, to: 'news', title: 'header.news' },
    { id: 7, to: 'generators', title: 'header.generators' },
    { id: 8, to: 'owners', title: 'header.owners' },
]

const adminSettingsRoutes = [
    { id: 1, to: 'tariffs-settings', title: 'header.tariffs' },
    { id: 2, to: 'ag-validity-period', title: 'header.agPeriod' },
    { id: 3, to: 'certificate-validity-period', title: 'header.certPeriod' },
    { id: 4, to: 'system-dates-settings', title: 'header.dates' },
    { id: 5, to: 'billing', title: 'header.billing' },
]

const dataRoutes = [
    { id: 1, to: 'ku-approval', title: 'header.kuApproval' },
    { id: 2, to: 'ku-data', title: 'header.kuData' },
    { id: 3, to: 'zd-data', title: 'header.zuData' },
    { id: 4, to: 'fuel-approval', title: 'header.fuelApproval' },
    { id: 5, to: 'go-approval', title: 'header.goApproval' },
]

interface IAdminNavigation {
    isAdmin: boolean
}

// disabled && style.disabled TODO удалить как разблокируют разделы

const AdminNavigation: FC<IAdminNavigation> = ({ isAdmin }) => {
    const location = useLocation()
    const { t } = useTranslation()
    const usersRoutes = [
        { id: 1, to: 'users', title: 'header.users' },
//        { id: 1, to: 'users', title: 'Пользователи', disabled: !isAdmin },
        { id: 2, to: 'user-appeals', title: 'header.userAppeals' },
        { id: 3, to: 'questionnaire-approval', title: 'header.approval' },
    ]

    const menu = (routes: any[]) => {
        return (
            <Menu className={style.menu}>
                {routes?.map(
                    (route: {
                        id: number
                        to: string
                        title: string
                        disabled: boolean | undefined
                    }) => {
                        const { id, to, title, disabled } = route
                        return (
                            <Menu.Item
                                key={id}
                                className={disabled ? style.disabled : ''}
                                disabled={disabled}
                            >
                                {!disabled ? (
                                    <NavLink
                                        to={to}
                                        className={(navData) =>
                                            navData.isActive ? style.subLinkActive : style.subLink
                                        }
                                    >
                                        {t(title)}
                                    </NavLink>
                                ) : (
                                    t(title)
                                )}
                            </Menu.Item>
                        )
                    },
                )}
            </Menu>
        )
    }

    return (
        <nav className={style.navBar}>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="main"
            >
                Главная
            </NavLink>
            <Dropdown overlay={menu(generalRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            generalRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Общие разделы
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <Dropdown overlay={menu(usersRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            usersRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Пользователи
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="registry"
            >
                Реестр платежных операций
            </NavLink>
            {isAdmin && (
                <Dropdown overlay={menu(adminSettingsRoutes)} className={style.navLink}>
                    <div>
                        <div
                            className={
                                adminSettingsRoutes.some((route) =>
                                    location.pathname.includes(route.to),
                                )
                                    ? style.navLinkActive
                                    : style.navLink
                            }
                        >
                            Настройки
                        </div>
                        <DownMini />
                    </div>
                </Dropdown>
            )}
            <Dropdown overlay={menu(dataRoutes)} className={style.navLink}>
                <div>
                    <div
                        className={
                            dataRoutes.some((route) => location.pathname.includes(route.to))
                                ? style.navLinkActive
                                : style.navLink
                        }
                    >
                        Работа с данными
                    </div>
                    <DownMini />
                </div>
            </Dropdown>
            <NavLink
                className={(navData) => (navData.isActive ? style.navLinkActive : style.navLink)}
                to="audit"
            >
                Аудит
            </NavLink>
        </nav>
    )
}

export default AdminNavigation
