import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const LogoGreenETrack: FC = () => (
    <AntIcon
        component={() => (
            <svg
                version="1.1"
                width="180"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 822.5 240"
                xmlSpace="preserve"
            >
                <g id="Layer_1">
                    <path
                        fill="#00C0F3"
                        d="M719.3,132.7l21.3,28.3h-17.5l-17.1-23.9v23.9h-14.3V87.6h14.3v42l16.1-22h16.3L719.3,132.7z"
                    />
                    <g>
                        <path
                            fill="#00C0F3"
                            d="M403.7,206.1h-3.1v-3.3h-10.9v-15.4h3.1v12.4h5.8v-12.4h3.1v12.4h2V206.1z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M409.3,196.4v3.4h7.6v3h-10.8v-15.4h10.5v3h-7.4v3.1h6.6v2.9H409.3z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M429.3,193.4v-6h3.1v15.4h-3.1v-6.4H423v6.4h-3.1v-15.4h3.1v6H429.3z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M442.3,202.8h-3.1v-12.4h-4.5v-3h12.1v3h-4.5V202.8z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M454.8,187.4c1,0,1.9,0.2,2.7,0.7c0.8,0.5,1.5,1.1,1.9,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7
                            c-0.5,0.8-1.1,1.4-1.9,1.9c-0.8,0.5-1.7,0.7-2.7,0.7h-2.7v5H449v-15.4H454.8z M454.8,194.8c0.6,0,1.2-0.2,1.6-0.6s0.7-0.9,0.7-1.6
                            c0-0.6-0.2-1.1-0.7-1.6s-1-0.6-1.6-0.6h-2.7v4.4H454.8z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M479,188.2c1.2,0.7,2.1,1.7,2.8,2.9s1,2.6,1,4s-0.3,2.8-1,4s-1.6,2.2-2.8,2.9c-1.2,0.7-2.4,1.1-3.7,1.1
                            c-1.3,0-2.4-0.3-3.4-0.8s-1.8-1.2-2.4-2c-0.6-0.8-1-1.7-1.3-2.6h3.3c0.3,0.6,0.7,1.1,1.4,1.6c0.6,0.5,1.4,0.7,2.4,0.7
                            c1,0,1.8-0.3,2.6-1s1.3-1.5,1.6-2.6h-5.3v-2.8h5.3c-0.3-1.1-0.8-1.9-1.6-2.6s-1.7-1-2.6-1c-0.9,0-1.7,0.2-2.3,0.7
                            c-0.6,0.5-1.1,1-1.3,1.6h-3.3c0.2-0.9,0.7-1.8,1.3-2.6c0.6-0.8,1.4-1.5,2.4-2c1-0.5,2.1-0.8,3.3-0.8
                            C476.6,187.1,477.8,187.4,479,188.2z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M494.9,193.4v-6h3.1v15.4h-3.1v-6.4h-6.3v6.4h-3.1v-15.4h3.1v6H494.9z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M504.7,196.4v3.4h7.6v3h-10.8v-15.4h10.5v3h-7.4v3.1h6.6v2.9H504.7z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M521.2,187.4c1,0,1.9,0.2,2.7,0.7c0.8,0.5,1.5,1.1,1.9,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7
                            c-0.5,0.8-1.1,1.4-1.9,1.9c-0.8,0.5-1.7,0.7-2.7,0.7h-2.7v5h-3.1v-15.4H521.2z M521.1,194.8c0.6,0,1.2-0.2,1.6-0.6
                            s0.7-0.9,0.7-1.6c0-0.6-0.2-1.1-0.7-1.6s-1-0.6-1.6-0.6h-2.7v4.4H521.1z"
                        />
                        <path fill="#00C0F3" d="M532.1,202.8H529v-15.4h9.8v3h-6.6V202.8z"/>
                        <path
                            fill="#00C0F3"
                            d="M543.3,202c-1.2-0.7-2.1-1.7-2.8-2.9s-1-2.6-1-4s0.3-2.8,1-4s1.6-2.2,2.8-2.9c1.2-0.7,2.4-1.1,3.8-1.1
                            c1.3,0,2.6,0.4,3.8,1.1c1.2,0.7,2.1,1.7,2.8,2.9c0.7,1.2,1,2.6,1,4s-0.3,2.8-1,4c-0.7,1.2-1.6,2.2-2.8,2.9
                            c-1.2,0.7-2.4,1.1-3.8,1.1C545.7,203.1,544.5,202.7,543.3,202z M549.3,199.4c0.7-0.4,1.2-1,1.6-1.8c0.4-0.8,0.6-1.6,0.6-2.5
                            s-0.2-1.8-0.6-2.5c-0.4-0.8-0.9-1.3-1.6-1.8c-0.7-0.4-1.4-0.6-2.2-0.6s-1.5,0.2-2.2,0.6c-0.7,0.4-1.2,1-1.6,1.8
                            c-0.4,0.8-0.6,1.6-0.6,2.5s0.2,1.8,0.6,2.5c0.4,0.8,0.9,1.3,1.6,1.8c0.7,0.4,1.4,0.6,2.2,0.6S548.6,199.8,549.3,199.4z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M560,202c-1.2-0.7-2.1-1.7-2.8-2.9s-1-2.6-1-4s0.3-2.8,1-4s1.6-2.2,2.8-2.9c1.2-0.7,2.4-1.1,3.8-1.1
                            c1.2,0,2.3,0.3,3.3,0.8c1,0.6,1.8,1.3,2.4,2.1c0.6,0.9,1.1,1.8,1.3,2.8h-3.3c-0.2-0.7-0.6-1.4-1.3-1.9s-1.5-0.8-2.4-0.8
                            c-0.8,0-1.5,0.2-2.2,0.6c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.8-0.6,1.6-0.6,2.5s0.2,1.8,0.6,2.5c0.4,0.8,0.9,1.3,1.6,1.8
                            c0.7,0.4,1.4,0.6,2.2,0.6c1,0,1.8-0.3,2.4-0.8c0.7-0.5,1.1-1.2,1.4-1.9h3.3c-0.2,1-0.7,1.9-1.3,2.8s-1.4,1.6-2.4,2.1
                            c-1,0.5-2.1,0.8-3.4,0.8C562.5,203.1,561.2,202.7,560,202z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M576.5,196.4v3.4h7.6v3h-10.8v-15.4h10.5v3h-7.4v3.1h6.6v2.9H576.5z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M593,187.4c1,0,1.9,0.2,2.7,0.7c0.8,0.5,1.5,1.1,1.9,1.9c0.5,0.8,0.7,1.7,0.7,2.7s-0.2,1.9-0.7,2.7
                            c-0.5,0.8-1.1,1.4-1.9,1.9c-0.8,0.5-1.7,0.7-2.7,0.7h-2.7v5h-3.1v-15.4H593z M592.9,194.8c0.6,0,1.2-0.2,1.6-0.6s0.7-0.9,0.7-1.6
                            c0-0.6-0.2-1.1-0.7-1.6s-1-0.6-1.6-0.6h-2.7v4.4H592.9z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M607.6,202.8h-3.1v-12.4H600v-3h12.1v3h-4.5V202.8z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M617.4,197.7l6.8-10.3h2.9v15.4h-3v-10.3l-6.8,10.3h-2.9v-15.4h3V197.7z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M645.5,198.1c-0.6,0.9-1.4,1.6-2.5,2.2c-1,0.5-2.2,0.8-3.5,0.8v2h-3.1v-2c-1.3,0-2.5-0.3-3.5-0.8
                            c-1-0.5-1.9-1.2-2.5-2.2c-0.6-0.9-0.9-2-0.9-3.2c0-1.2,0.3-2.3,0.9-3.2c0.6-0.9,1.4-1.6,2.5-2.2c1-0.5,2.2-0.8,3.5-0.8v-1.6h3.1
                            v1.6c1.3,0,2.5,0.3,3.5,0.8c1,0.5,1.9,1.2,2.5,2.2c0.6,0.9,0.9,2,0.9,3.2C646.4,196.1,646.1,197.1,645.5,198.1z M633.7,197.2
                            c0.7,0.6,1.6,0.9,2.8,1v-6.6c-1.1,0-2.1,0.3-2.8,1c-0.7,0.6-1,1.4-1,2.3C632.7,195.8,633,196.6,633.7,197.2z M639.4,198.2
                            c1.1,0,2.1-0.3,2.8-1c0.7-0.6,1-1.4,1-2.3c0-0.9-0.3-1.7-1-2.3c-0.7-0.6-1.6-0.9-2.8-1V198.2z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M651.9,197.7l6.8-10.3h2.9v15.4h-3v-10.3l-6.8,10.3h-2.9v-15.4h3V197.7z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M671.3,194.9l6,7.9h-3.9l-5.1-6.9v6.9h-3.1v-15.4h3.1v6.7l4.8-6.7h3.7L671.3,194.9z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M693,202.8h-3.3l-1.1-3.2h-5.8l-1.1,3.2h-3.2l5.7-15.4h3.1L693,202.8z M687.5,196.7l-1.9-5.4l-1.9,5.4H687.5z
                            "
                        />
                        <path
                            fill="#00C0F3"
                            d="M709.1,206.1h-3.1v-3.3H695v-15.4h3.1v12.4h5.8v-12.4h3.1v12.4h2V206.1z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M714.5,197.7l6.8-10.3h2.9v15.4h-3v-10.3l-6.8,10.3h-2.9v-15.4h3V197.7z"
                        />
                        <path
                            fill="#00C0F3"
                            d="M730.9,197.7l6.8-10.3h2.9v15.4h-3v-10.3l-6.8,10.3h-2.9v-15.4h3V197.7z"
                        />
                    </g>
                    <g>
                        <g>
                            <path
                                fill="#39D353"
                                d="M168.9,161.1v-53.5h10.7l2.3,5.2h0.5c1-1.4,2.6-2.9,4.8-4.4c2.1-1.5,4.7-2.3,7.7-2.3c1.9,0,3.5,0.2,4.8,0.5
                                V121c-1.3-0.3-3.1-0.5-5.4-0.5c-3.4,0-6.2,1-8.2,3.1c-2,2.1-3,4.9-3,8.6v29H168.9z"
                            />
                            <path
                                fill="#39D353"
                                d="M253.1,139.5h-38.5c0.8,3.2,2.3,5.7,4.6,7.6c2.3,1.8,5,2.7,8.2,2.7c3.9,0,7.1-1.3,9.6-4h15
                                c-1,2.6-2.7,5.2-5,7.8c-2.3,2.6-5.1,4.7-8.5,6.4c-3.4,1.7-7,2.5-10.9,2.5c-5,0-9.6-1.3-13.8-3.8c-4.2-2.5-7.5-5.9-10-10.2
                                c-2.5-4.3-3.7-9-3.7-14.1c0-5,1.2-9.6,3.7-14c2.5-4.3,5.8-7.8,10-10.3c4.2-2.6,8.7-3.8,13.4-3.8c4.8,0,9.3,1.2,13.3,3.6
                                c4,2.4,7.2,5.7,9.6,9.8c2.3,4.2,3.5,8.8,3.5,13.9C253.7,136.1,253.5,138.1,253.1,139.5z M219.3,121.7c-2.3,1.9-3.9,4.4-4.7,7.6
                                H240c-0.8-3.1-2.4-5.7-4.7-7.6c-2.3-1.9-4.9-2.8-7.9-2.8C224.3,118.9,221.6,119.8,219.3,121.7z"
                            />
                            <path
                                fill="#39D353"
                                d="M311.7,139.5h-38.5c0.8,3.2,2.3,5.7,4.6,7.6c2.3,1.8,5,2.7,8.2,2.7c3.9,0,7.1-1.3,9.6-4h15
                                c-1,2.6-2.7,5.2-5,7.8c-2.3,2.6-5.1,4.7-8.5,6.4c-3.4,1.7-7,2.5-10.9,2.5c-5,0-9.6-1.3-13.8-3.8c-4.2-2.5-7.5-5.9-10-10.2
                                c-2.5-4.3-3.7-9-3.7-14.1c0-5,1.2-9.6,3.7-14c2.5-4.3,5.8-7.8,10-10.3c4.2-2.6,8.7-3.8,13.4-3.8c4.8,0,9.3,1.2,13.3,3.6
                                c4,2.4,7.2,5.7,9.6,9.8c2.3,4.2,3.5,8.8,3.5,13.9C312.3,136.1,312.1,138.1,311.7,139.5z M277.9,121.7c-2.3,1.9-3.9,4.4-4.7,7.6
                                h25.4c-0.8-3.1-2.4-5.7-4.7-7.6c-2.3-1.9-4.9-2.8-7.9-2.8C282.9,118.9,280.2,119.8,277.9,121.7z"
                            />
                            <path
                                fill="#39D353"
                                d="M318.5,161.1v-53.5h10.7l2.3,5.2h0.5c1.1-1.4,3-2.9,5.6-4.4c2.6-1.5,5.6-2.3,9-2.3c4.3,0,8,1,11.3,2.9
                                c3.3,1.9,5.8,4.7,7.6,8.2c1.8,3.5,2.7,7.6,2.7,12.2v31.6H354v-30.3c0-3.2-1-5.8-3-8c-2-2.2-4.6-3.3-7.6-3.3
                                c-3.1,0-5.6,1.1-7.6,3.3c-2,2.2-3,4.9-3,8.1v30.3H318.5z"
                            />
                        </g>
                        <path
                            fill="#39D353"
                            d="M161.8,114.8h-40v13.9h24.3c-3,10.6-12.8,18.3-24.3,18.3c-14,0-25.3-11.3-25.3-25.3s11.3-25.3,25.3-25.3
                            c8.8,0,16.6,4.5,21.1,11.4h17c-5.7-15.6-20.6-26.6-38.1-26.6c-22.4,0-40.6,18.2-40.6,40.6s18.2,40.6,40.6,40.6
                            c22.4,0,40.6-18.2,40.6-40.6C162.4,119.4,162.2,117.1,161.8,114.8z"
                        />
                    </g>
                    <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="401.3398"
                        y1="93.0773"
                        x2="458.7479"
                        y2="150.4854"
                    >
                        <stop offset="9.765625e-04" style={{stopColor: '#39D353'}}/>
                        <stop offset="0.5097" style={{stopColor: '#1DCAA3'}}/>
                        <stop offset="1" style={{stopColor: '#00C0F3'}}/>
                    </linearGradient>
                    <path
                        fill="url(#SVGID_1_)"
                        d="M470,128.8h-40v-13.9h24.3c-3-10.6-12.8-18.3-24.3-18.3c-14,0-25.3,11.3-25.3,25.3s11.3,25.3,25.3,25.3
                        c8.8,0,16.6-4.5,21.1-11.4h17c-5.7,15.6-20.6,26.6-38.1,26.6c-22.4,0-40.6-18.2-40.6-40.6s18.2-40.6,40.6-40.6
                        c22.4,0,40.6,18.2,40.6,40.6C470.6,124.2,470.4,126.5,470,128.8z"
                    />
                    <path
                        fill="#00C0F3"
                        d="M547.3,161.1v-53.5H558l2.3,5.2h0.5c1-1.4,2.6-2.9,4.8-4.4c2.1-1.5,4.7-2.3,7.7-2.3c1.9,0,3.5,0.2,4.8,0.5V121
                        c-1.3-0.3-3.1-0.5-5.4-0.5c-3.4,0-6.2,1-8.2,3.1c-2,2.1-3,4.9-3,8.6v29H547.3z"
                    />
                    <path
                        fill="#00C0F3"
                        d="M622.3,112.4c4,4.2,6,9.7,6,16.6v32h-10.7l-2.3-5.2h-0.5c-4.2,4.4-9.3,6.6-15.2,6.6c-3.4,0-6.4-0.7-9.2-2.1
                        c-2.8-1.4-4.9-3.3-6.5-5.8c-1.6-2.4-2.4-5.1-2.4-8.1c0-3.6,1.1-6.8,3.3-9.5c2.2-2.7,5.2-4.8,9.1-6.2c3.9-1.4,8.3-2.2,13.4-2.2h6.8
                        v-1c0-2.7-0.7-4.9-2.2-6.4c-1.5-1.5-3.5-2.3-6-2.3c-2.2,0-3.9,0.6-5.2,1.7c-1.3,1.2-2.1,2.4-2.4,3.7H584c0.3-3.2,1.3-6.1,3-8.9
                        c1.7-2.8,4.2-5,7.4-6.7c3.2-1.7,7.1-2.6,11.5-2.6C612.9,106.2,618.3,108.3,622.3,112.4z M610.9,147.9c2.1-2,3.1-4.3,3.1-6.9v-2.7
                        h-7.2c-3.9,0-6.7,0.6-8.4,1.8c-1.8,1.2-2.6,2.9-2.6,5.1c0,1.7,0.6,3,1.8,4.1c1.2,1,3,1.6,5.3,1.6
                        C606.2,150.8,608.9,149.8,610.9,147.9z"
                    />
                    <path
                        fill="#00C0F3"
                        d="M646.7,158.6c-4.2-2.6-7.6-6-10.1-10.3c-2.5-4.3-3.8-9-3.8-14c0-5,1.3-9.6,3.8-14c2.5-4.3,5.9-7.8,10.1-10.3
                        c4.2-2.6,8.7-3.8,13.5-3.8c4.4,0,8.4,1,12.1,3.1c3.6,2.1,6.6,4.8,8.9,8.1c2.3,3.3,3.8,6.8,4.5,10.3h-14.3c-0.3-1-0.9-2.1-1.8-3.4
                        c-0.9-1.3-2.2-2.4-3.8-3.4c-1.6-1-3.5-1.5-5.7-1.5c-3.8,0-6.9,1.4-9.4,4.2c-2.5,2.8-3.7,6.4-3.7,10.7c0,4.3,1.2,7.9,3.7,10.7
                        c2.5,2.8,5.6,4.2,9.4,4.2c2.2,0,4.1-0.5,5.7-1.5c1.6-1,2.9-2.1,3.8-3.4c0.9-1.3,1.5-2.4,1.8-3.4h14.3c-0.7,3.6-2.2,7-4.5,10.3
                        c-2.3,3.3-5.3,6-8.9,8.1c-3.6,2.1-7.7,3.1-12.1,3.1C655.4,162.5,650.9,161.2,646.7,158.6z"
                    />
                    <path fill="#00C0F3" d="M524,161.1h-16V98.2h-22.7V82.7h61.4v15.4H524V161.1z" />
                </g>
            </svg>
        )}
    />
)