import { FC, useEffect, useState } from 'react'
import { CardPanel } from './components'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { PrimaryIconButton, CustomModal, IconButton, DeleteModal } from '../ui'

import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Form, Input, Space, Spin } from 'antd'
import { Edit, Trash, AddCircle } from '../../assets/icons'
import { useAppSelector } from '../../store/hooks'
import { EUserRole } from '../../constants/approval'
import {useLocation} from "react-router-dom";

export const Questions: FC = () => {
    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isAdmin = [EUserRole.Administrator, userRole === EUserRole.SuperAdmin].includes(userRole)

    const { t } = useTranslation()

    const [formQuestion] = Form.useForm()
    const [showQuestionsModal, setShowQuestionsModal] = useState(false)
    const [guestionId, setQuestionId] = useState('')
    const [typeQuestions, setTypeQuestions] = useState('create')
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState('')

    const handleAddQuestion = () => {
        setTypeQuestions('create')
        formQuestion.setFieldsValue({ answer: undefined, number: undefined, question: undefined })
        setShowQuestionsModal(true)
    }

    // создание/редактирование вопроса
    const {
        fetch: postQuestion,
        result: responsePostQuestion,
        isLoading: isLoadingPostQuestion,
    } = useRequest({
        link: `/api/content/v1/questions`,
        method: RequestTypes.post,
    })

    // удаление вопроса
    const {
        fetch: delQuestion,
        result: responseDelQuestion,
        isLoading: isLoadingDelQuestion,
    } = useRequest({
        link: `/api/content/v1/questions`,
        method: RequestTypes.delete,
    })

    // получение списка вопросов
    const {
        fetch: getQuestions,
        result: questions,
        isLoading: isLoadingGetQuestions,
    } = useRequest({
        link: `/api/content/v1/questions`,
        method: RequestTypes.get,
    })

    useEffect(() => {
        getQuestions({
            body: {},
        })
    }, [responsePostQuestion])

    const onFinishQuestion = async (values: any) => {
        formQuestion.validateFields(['answer', 'number', 'question'])

        const body = {
            ...values,
            number: +values.number,
            ...(typeQuestions === 'change' && { id: guestionId }),
        }
        postQuestion({
            body,
            addToLink: `/${typeQuestions}`,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Сохранение прошло успешно',
            },
            onSuccess: () => {
                getQuestions({
                    body: {},
                })
                setShowQuestionsModal(false)
            },
            errorCodeMessage: true,
        })
    }

    const handleClickEditQuestion = (q: any) => {
        formQuestion.setFieldsValue({ answer: q.answer, number: q.number, question: q.question })
        setTypeQuestions('change')

        setQuestionId(q.id)
        setShowQuestionsModal(q.id)
    }

    const handleClickDeleteQuestion = () => {
        delQuestion({
            body: {},
            addToLink: `?id=${showDeleteQuestionModal}`,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Удаление прошло успешно',
            },
            onSuccess: () => {
                getQuestions({
                    body: {},
                })
            },
            errorCodeMessage: true,
        })
        setShowDeleteQuestionModal('')
    }

    const { hash } = useLocation()
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && hash) {
            const element = document.getElementById(hash.substring(1))
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }, [hash, isMounted])

    return (
        <div className={style.faq}>
            <div className={style.faqTitle} id="faq_block">{t('documents.faq')}</div>
            {isAdmin && (
                <IconButton
                    icon={<AddCircle />}
                    style={{ marginBottom: '32px' }}
                    onClick={handleAddQuestion}
                >
                    {t('documents.addQuestion')}
                </IconButton>
            )}
            <CustomModal
                isModalVisible={showQuestionsModal}
                styleWrapper={{ marginBottom: '24px' }}
                styleButtonsBlock={{ display: 'flex' }}
                params={{
                    title:
                        typeQuestions === 'create'
                            ? t('documents.addingQuestion')
                            : t('documents.editingQuestion'),
                    cancelTextButton: t('documents.cancel'),
                    confirmTextButton: t('documents.confirm'),
                }}
                children={
                    <div>
                        <Form
                            layout="vertical"
                            form={formQuestion}
                            onFinish={onFinishQuestion}
                            name="formQuestions"
                            className={style.form}
                            validateTrigger={['onSubmit', 'onBlur', 'onChange']}
                        >
                            <Form.Item
                                label={t('documents.number')}
                                name="number" // Тип 1 - Документы, 2 - Нормативные акты, 3 - Регистрация, 4 - Стандартные формы, 5 - Отчеты
                                rules={[{ required: true, message: t('rules.required') }]}
                            >
                                <Input
                                    className={style.inputMiddle}
                                    placeholder={`${t('documents.formItemPrefix')} ${t(
                                        'documents.number',
                                    ).toLocaleLowerCase()}`}
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('documents.question')}
                                name="question"
                                rules={[{ required: true, message: t('rules.required') }]}
                            >
                                <Input.TextArea
                                    placeholder={`${t('documents.formItemPrefix')} ${t(
                                        'documents.question',
                                    ).toLocaleLowerCase()}`}
                                    style={{ minHeight: 45 }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('rules.answer')}
                                name="answer"
                                rules={[{ required: true, message: t('rules.required') }]}
                            >
                                <Input.TextArea
                                    placeholder={`${t('documents.formItemPrefix')} ${t(
                                        'documents.answer',
                                    ).toLocaleLowerCase()}`}
                                    style={{ minHeight: 180 }}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                }
                onClose={() => setShowQuestionsModal(false)}
                closeAfterConfirm={false}
                onConfirm={() => {
                    formQuestion.submit()
                }}
            />
            <Spin spinning={isLoadingGetQuestions || isLoadingPostQuestion}>
                <div className={style.faqList}>
                    {(questions || []).map((q: any) => {
                        return (
                            <CardPanel
                                defaultActiveKey={'1'}
                                className={style.faqCard}
                                headerClassName={style.headerClassName}
                                key={q.number}
                                panelKey={q.number}
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginRight: '4px',
                                        }}
                                    >
                                        <div
                                            className={style.faqHeader}
                                        >{`${q.number}. ${q.question}`}</div>
                                        {isAdmin && (
                                            <Space
                                                style={{ alignItems: 'start' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            >
                                                {
                                                    <PrimaryIconButton
                                                        customStyle={{
                                                            marginLeft: '8px',
                                                        }}
                                                        icon={<Edit />}
                                                        onClick={() => handleClickEditQuestion(q)}
                                                        loading={
                                                            isLoadingPostQuestion &&
                                                            guestionId === q.id
                                                        }
                                                    />
                                                }
                                                {
                                                    <PrimaryIconButton
                                                        isCancel
                                                        icon={<Trash />}
                                                        onClick={() => {
                                                            setShowDeleteQuestionModal(q.id)
                                                        }}
                                                        loading={
                                                            isLoadingDelQuestion &&
                                                            showDeleteQuestionModal === q.id
                                                        }
                                                    />
                                                }
                                            </Space>
                                        )}
                                    </div>
                                }
                            >
                                <p className={style.faqText}>{q.answer || ''}</p>
                            </CardPanel>
                        )
                    })}

                    <DeleteModal
                        isModalVisible={!!showDeleteQuestionModal}
                        headerText={t('documents.delQuestion')}
                        messageText={`${t('documents.delQuestionQuestion')} “${
                            (questions || []).find((q: any) => q.id == showDeleteQuestionModal)
                                ?.question || ''
                        }” ?`}
                        onCancel={() => setShowDeleteQuestionModal('')}
                        onDelete={() => handleClickDeleteQuestion()}
                    />
                </div>
            </Spin>
        </div>
    )
}

export default Questions
