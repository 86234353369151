import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, TimeRangePicker } from '../../../ui'
import useRequest from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'
import StackedBarChart from './StackedBarChart'
import { getGraphData } from './config'

import style from './style.m.less'

const StackedBarChartFrame: FC = () => {
    const { t } = useTranslation()

    const options = [
        { label: t('main.released'), id: '835cdaf1-a0ec-4379-9a5e-b225be191148' },
        { label: t('main.paid'), id: 'z9d9f398-2383-48f6-b2b9-11caad7abb1a' },
        { label: t('main.overtime'), id: 'm1a34b33-03be-4211-bc66-c209c6b52e4f' },
    ]

    const [toolType, setToolType] = useState(options[0].label)

    const onChange = (value: string) => {
        setToolType(value)
    }

    const { fetch, result } = useRequest(getGraphData)

    useMount(() => {
        fetch({})
    })

    return (
        <div className={style.stackedBarChart}>
            <div className={style.headerRow}>
                <div>
                    <span className={style.headerPrimary}>{t('main.generationAttributes')}</span>
                    <span className={style.headerSecondary}>({t('main.scale')})</span>
                </div>
                <div>
                    {options.map((item) => {
                        const { label, id } = item
                        return (
                            <Tag
                                color={toolType === label ? 'blue' : undefined}
                                style={{ cursor: 'pointer' }}
                                key={id}
                                onClick={() => onChange(label)}
                            >
                                {label}
                            </Tag>
                        )
                    })}
                </div>
            </div>
            <div className={style.graph}>
                <StackedBarChart graphData={result?.stats} toolType={toolType} />
            </div>
        </div>
    )
}

export default StackedBarChartFrame
