import { NoteAdd, Star, KeyboardOpen } from '../../assets/icons'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ELoading } from '../../store/dictionaries/interfaces'
import { TextCard } from './components'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import style from './style.m.less'
import { IconButtonLarge } from '../../components/ui'
import Graph from './components/Graph/GraphFrame'
import StackedBarChart from './components/StackedBarChart/StackedBarChartFrame'
import GreenToolProgress from './components/GreenToolProgress/GreenToolProgress'
import OtherNews from '../../../src/components/OtherNews/OtherNews'
import useMount from '../hooks/useMount'
import { getDictionaries } from '../../store/dictionaries'
import { LogoGreenETrack } from './components'

const Main: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    useMount(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    const handleClickNews = () => {
        navigate('/news/123')
    }

    return (
        <div className={style.main}>
            <div className={style.mainScreen}>
                <div className={style.container}>
                    <h1 className={style.title}>{t('main.title')}</h1>
                    <div className={style.buttonGroup}>
                        <button className={style.button} onClick={() => navigate('/generators')}>
                            {t('main.generateObjs')}
                        </button>
                        <button className={style.button} onClick={() => navigate('/owners')}>
                            {t('main.generateOwners')}
                        </button>
                        <button className={style.button} onClick={() => navigate('/documents#faq_block')}>
                            {t('main.aboutFAQ')}
                        </button>
                        <a className={style.buttonGradient} href="https://green-e-track.ru/" rel="external">
                            {t('main.greenETrack')}
                        </a>
                    </div>
                </div>
            </div>
            <div className={style.generateObjects}>
                <div className={style.container}>
                    <h2 className={style.secondTitle}>{t('main.generateObjsInfo')}</h2>
                    <div className={style.graph}>
                        <Graph />
                    </div>
                </div>
            </div>
            <div className={style.greenTools}>
                <div className={style.container}>
                    <h2 className={style.secondTitle}>{t('main.greenTools')}</h2>
                    <p className={style.blackText}>{t('main.greenToolsDescription')}</p>
                    <div className={style.graph}>
                        <StackedBarChart />
                    </div>
                    <div className={style.graph}>
                        <GreenToolProgress />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
