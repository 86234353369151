import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import style from './style.m.less'

const routes = [
    { id: 1, to: 'main', title: 'header.main' },
    { id: 2, to: 'about', title: 'header.about'},
    { id: 3, to: 'documents', title: 'header.documents' },
    { id: 4, to: 'green-tools', title: 'header.greenTools', addUrls: ['certificates-in-pledge'] },
    { id: 5, to: 'qualification', title: 'header.qualification' },
    { id: 6, to: 'news', title: 'header.news' },
]

const Navigation: FC = () => {
    const { t } = useTranslation()

    const navTabs = routes.map((route) => {
        const { id, to, title } = route
        return (
            <NavLink
                className={(navData) => {
                    return navData.isActive ||
                        (route.addUrls &&
                            route.addUrls.some((url: string) => location.pathname.includes(url)))
                        ? style.navLinkActive
                        : style.navLink
                }}
                key={id}
                to={to}
            >
                {t(title)}
            </NavLink>
        )
    })

    return <nav className={style.navBar}>{navTabs}</nav>
}

export default Navigation
