import { AddCircle } from './AddCircle'
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'
import { ArrowSwap } from './ArrowSwap'
import { Battery } from './Battery'
import { Bell } from './Bell'
import { Blocks } from './Blocks'
import { BlueLock } from './BlueLock'
import { BookmarkMarked } from './BookmarkMarked'
import { BookmarkPlus } from './BookmarkPlus'
import { BookSaved } from './BookSaved'
import { Calendar } from './Calendar'
import { CheckCircle } from './CheckCircle'
import { CircleRubIn } from './CircleRubIn'
import { CircleRubOut } from './CircleRubOut'
import { Close } from './Close'
import { CloseCircle } from './CloseCircle'
import { Copy } from './Copy'
import { DefaultDoc } from './DefaultDoc'
import { DocIcon } from './DocIcon'
import { DoubleSave } from './DoubleSave'
import { Down } from './Down'
import { DownMini } from './DownMini'
import { Drop } from './Drop'
import { Edit } from './Edit'
import { Email } from './Email'
import { EmptyWallet } from './EmptyWallet'
import { Export } from './Export'
import { Filter } from './Filter'
import { Flag } from './Flag'
import { Flame } from './Flame'
import { Flash } from './Flash'
import { FolderCross } from './FolderCross'
import { FooterLogo } from './FooterLogo'
import { Import } from './Import'
import { InfoCircle } from './InfoCircle'
import { InputIcon } from './InputIcon'
import { Key } from './Key'
import { KeyboardOpen } from './KeyboardOpen'
import { Location } from './Location'
import { Lock } from './Lock'
import { Logo } from './Logo'
import { Logout } from './Logout'
import { Mail } from './Mail'
import { Mail2 } from './Mail2'
import { More } from './More'
import { NewNotification } from './NewNotification'
import { NoData } from './NoData'
import { NotVerify } from './NotVerify'
import { NoteAdd } from './NoteAdd'
import { Notification } from './Notification'
import { PdfIcon } from './PdfIcon'
import { PngIcon } from './PngIcon'
import { QuestionCircle } from './QuestionCircle'
import { Receipt } from './Receipt'
import { RectangleListMarked } from './RectangleListMarked'
import { RotateRight } from './RotateRight'
import { RowVertical } from './RowVertical'
import { Search } from './Search'
import { Settings } from './Settings'
import { SimpleStar } from './SimpleStar'
import { Sort1 } from './Sort1'
import { Sort2 } from './Sort2'
import { Stamp } from './Stamp'
import { Star } from './Star'
import { Telegram } from './Telegram'
import { Telegram2 } from './Telegram2'
import { Tick } from './Tick'
import { TickSquare } from './TickSquare'
import { Tile } from './Tile'
import { Transfer } from './Transfer'
import { MoneyOut } from './MoneyOut'
import { Trash } from './Trash'
import { User } from './User'
import { Verify } from './Verify'
import { Warning } from './Warning'
import { XlsIcon } from './XlsIcon'
import { Clock } from './Clock'
import { TickCircle } from './TickCircle'
import { Note } from './Note'
import { NewLogo } from './NewLogo'
import { NewLogoGET } from './NewLogoGET'
import { FileSwap } from './FileSwap'
import { DoubleBookmark } from './DoubleBookmark'
import { AddCircleHistory } from './AddCircleHistory'
import { LogoGreenETrack } from './LogoGreenETrack'

export {
    AddCircle,
    ArrowLeft,
    ArrowRight,
    ArrowSwap,
    Battery,
    Bell,
    Blocks,
    BlueLock,
    BookmarkMarked,
    BookmarkPlus,
    BookSaved,
    Calendar,
    CheckCircle,
    CircleRubIn,
    CircleRubOut,
    Close,
    CloseCircle,
    Copy,
    DefaultDoc,
    DocIcon,
    DoubleSave,
    Down,
    DownMini,
    Drop,
    Edit,
    Email,
    EmptyWallet,
    Export,
    Filter,
    Flag,
    Flame,
    Flash,
    FolderCross,
    FooterLogo,
    Import,
    InfoCircle,
    InputIcon,
    Key,
    KeyboardOpen,
    Location,
    Lock,
    Logo,
    Logout,
    Mail,
    Mail2,
    More,
    NewNotification,
    NoData,
    NotVerify,
    NoteAdd,
    Notification,
    PdfIcon,
    PngIcon,
    QuestionCircle,
    Receipt,
    RectangleListMarked,
    RotateRight,
    RowVertical,
    Search,
    Settings,
    SimpleStar,
    Sort1,
    Sort2,
    Stamp,
    Star,
    Telegram,
    Telegram2,
    Tick,
    TickSquare,
    Tile,
    Transfer,
    MoneyOut,
    Trash,
    User,
    Verify,
    Warning,
    XlsIcon,
    Clock,
    TickCircle,
    Note,
    NewLogo,
    NewLogoGET,
    FileSwap,
    DoubleBookmark,
    AddCircleHistory,
    LogoGreenETrack,
}
