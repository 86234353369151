import { FC } from 'react'
import { Collapse, CollapsePanelProps } from 'antd'
import classNames from 'classnames'

import style from './style.m.less'
import { Down } from '../../../../assets/icons'

const { SubPanel: AntPanel } = Collapse

const SubPanel: FC<CollapsePanelProps> = (props) => {
    const realHeader = (
        <div className={classNames(style.header, style.small, { [style.active]: (props as any).isActive })}>
            {props.header}
            <span className={style.icon}>
                <Down />
            </span>
        </div>
    )
    return (
        <AntPanel
            showArrow={false}
            {...props}
            header={realHeader}
            className={classNames(style.panel, (props as any).className)}
        />
    )
}

export default SubPanel
