// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".panel_nfyGt .ant-collapse-content-active {\n  max-height: none;\n  overflow: hidden;\n}\n.panel_nfyGt .ant-collapse-header {\n  padding: 16px 0 !important;\n}\n.panel_nfyGt .ant-collapse-content-box {\n  padding: 16px 0 !important;\n}\n.header_fgVyd {\n  font-family: Raleway, sans-serif;\n  font-feature-settings: \"lnum\";\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  color: #262626;\n  position: relative;\n}\n.header_fgVyd .icon_WPgFo {\n  position: absolute;\n  left: calc(100% + 16px);\n  top: 1px;\n  width: 16px;\n  height: 17px;\n  transition: all 0.25s ease;\n  display: flex;\n}\n.header_fgVyd.active_XxIBS .icon_WPgFo {\n  transform: rotate(180deg);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Documents/components/SubPanel/style.m.less"],"names":[],"mappings":"AAAA;EAEQ,gBAAA;EACA,gBAAA;AAAR;AAHA;EAMQ,0BAAA;AAAR;AANA;EASQ,0BAAA;AAAR;AAKA;EACI,gCAAA;EACA,6BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAHJ;AAJA;EAUQ,kBAAA;EACA,uBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;EACA,aAAA;AAHR;AAMI;EACI,yBAAA;AAJR","sourcesContent":[".panel {\n    :global(.ant-collapse-content-active) {\n        max-height: none;\n        overflow: hidden;\n    }\n    :global(.ant-collapse-header) {\n        padding: 16px 0 !important;\n    }\n    :global(.ant-collapse-content-box) {\n        padding: 16px 0 !important;\n    }\n}\n\n\n.header {\n    font-family: Raleway, sans-serif;\n    font-feature-settings: \"lnum\";\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 20px;\n    color: #262626;\n    position: relative;\n\n    .icon {\n        position: absolute;\n        left: calc(100% + 16px);\n        top: 1px;\n        width: 16px;\n        height: 17px;\n        transition: all .25s ease;\n        display: flex;\n    }\n    \n    &.active .icon {\n        transform: rotate(180deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "panel_nfyGt",
	"header": "header_fgVyd",
	"icon": "icon_WPgFo",
	"active": "active_XxIBS"
};
export default ___CSS_LOADER_EXPORT___;
