// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contacts_Mh4oW {\n  margin-top: 80px;\n  margin-bottom: 100px;\n  display: grid;\n  grid-template-columns: 400px 1fr;\n  gap: 30px;\n}\n.contactsTitle_ODdgo {\n  font-family: Raleway, sans-serif;\n  font-feature-settings: 'lnum';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  color: #262626;\n}\n.contactsText_VXzOm {\n  width: 409px;\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #434343;\n  margin: 32px 0;\n}\n.contactsNumber_QIv4K {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #434343;\n  margin: 16px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/About/components/Contacts/style.m.less"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,oBAAA;EACA,aAAA;EACA,gCAAA;EACA,SAAA;AAAF;AAGA;EACE,gCAAA;EACA,6BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;AAIA;EACE,YAAA;EACA,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,cAAA;AAFF;AAKA;EACE,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,cAAA;AAHF","sourcesContent":["\n.contacts {\n  margin-top: 80px;\n  margin-bottom: 100px;\n  display: grid;\n  grid-template-columns: 400px 1fr;\n  gap: 30px;\n}\n\n.contactsTitle {\n  font-family: Raleway, sans-serif;\n  font-feature-settings: 'lnum';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  color: #262626;\n}\n\n.contactsText {\n  width: 409px;\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #434343;\n  margin: 32px 0;\n}\n\n.contactsNumber {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #434343;\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": "contacts_Mh4oW",
	"contactsTitle": "contactsTitle_ODdgo",
	"contactsText": "contactsText_VXzOm",
	"contactsNumber": "contactsNumber_QIv4K"
};
export default ___CSS_LOADER_EXPORT___;
