import { FC, useState } from 'react'
import { Input } from 'antd'
import { IconButton, TimeRangePicker, CertificateCard } from '../../../ui'
import style from './style.m.less'

const options = [
    { label: 'Сертификат происхождения', id: '065cdaf1-a0ec-4379-9a5e-b225be191148' },
    { label: 'Зеленый договор', id: '7ad9f398-2383-48f6-b2b9-11caad7abb1a' },
    { label: 'Сторонние инструменты', id: 'c6a34b33-03be-4211-bc66-c209c6b52e4f' },
]

const UnregisteredLayout: FC = () => {
    const [toolType, setToolType] = useState(options[0].label)

    const onChange = (value: string) => {
        setToolType(value)
    }

    return (
        <div className={style.mainWrapper}>
            <div className={style.registryInfoBlock}>
                Укажите данные о зеленом инструменте для ознакомления с доступной информацией.
                Регистрация позволяет получить доступ к Личному кабинету
            </div>
            <div className={style.selectorWrapper}>
                <div className={style.selectorHeader}>Что Вы хотите найти?</div>
                <div className={style.selector}>
                    {options.map((item) => {
                        const { label, id } = item
                        return (
                            <div
                                className={
                                    toolType === label ? style.greenToolSelected : style.greenTool
                                }
                                key={id}
                                onClick={() => onChange(label)}
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
                <div className={style.numberInputWrapper}>
                    <div>
                        <div>
                            Номер {toolType === 'Зеленый сертификат' ? 'сертификата' : 'договора'}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                className={style.certificateNumberInput}
                                placeholder="Введите номер сертификата"
                            />
                        </div>
                    </div>
                    {toolType !== 'Зеленый сертификат' && (
                        <div className={style.leftGapBlock}>
                            <div>Дата погашения</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker />
                            </div>
                        </div>
                    )}
                    {toolType === 'Сторонние инструменты' && (
                        <div className={style.leftGapBlock}>
                            <div>ИНН, СНИЛС или ОГРН</div>
                            <div className={style.inputBlock}>
                                <Input
                                    className={style.documentNumberInput}
                                    placeholder="Введите номер"
                                />
                            </div>
                        </div>
                    )}
                    <div className={style.leftGapBlock}>
                        <IconButton>Найти</IconButton>
                    </div>
                </div>
            </div>
            <CertificateCard toolType={toolType} />
        </div>
    )
}

export default UnregisteredLayout
