import {ConfigProvider, Select, Spin, Table} from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'

import style from './style.m.less'

import { columns } from './columns'
import MoreFilters from '../MoreFilters/MoreFilters'
import { IData, isAddedType } from './data'
import { userRoleMap } from '../../../../constants/approval'
import moment from 'moment'
import debounce from 'lodash/debounce'
import useRequest from '../../../hooks/useRequest'
import { getAuditEvent, getAuditEventTypes } from '../../config'
import useMount from '../../../hooks/useMount'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

interface ILoadEvents {
    pagination: number
    dateFilter: [moment.Moment, moment.Moment] | null
    typeEventIdFilter: number[]
    nameCreatorFilter: string
    roleCreatorFilter: number
    nameRecipientFilter: string
    roleRecipientFilter: number
    pageSize: number
}

interface IDefault {
    res: any
    events: any
}

const PAGE_SIZE = 10

const PAGE_SIZE_OPTIONS = [
    { value: 10, label: <span>10 / стр. </span> },
    { value: 20, label: <span>20 / стр. </span> },
    { value: 50, label: <span>50 / стр. </span> },
    { value: 100, label: <span>100 / стр. </span> },
]

const Default: FC<IDefault> = () => {
    const navigate = useNavigate()

    const { fetch: fetchTypes, isLoading: isLoadingTypes } = useRequest(getAuditEventTypes)
    const { fetch: fetchEvents, isLoading, result } = useRequest(getAuditEvent)
    const [eventTypes, setEventTypes] = useState<{
        options: any[]
        dictionary: any
    }>({
        options: [],
        dictionary: {},
    })
    const [pagination, setPagination] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [nameCreatorFilter, setNameCreatorFilter] = useState('')
    const [roleCreatorFilter, setRoleCreatorFilter] = useState(0)
    const [typeEventIdFilter, setTypeEventIdFilter] = useState<number[]>([])
    const [nameRecipientFilter, setNameRecipientFilter] = useState('')
    const [roleRecipientFilter, setRoleRecipientFilter] = useState(0)
    const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment] | null>(null)

    const onChangeNameCreator = (value: any) => {
        setPagination(1)
        setNameCreatorFilter(value)
    }
    const onChangeRoleCreator = (value: any) => {
        setPagination(1)
        setRoleCreatorFilter(value)
    }
    const onChangeEventId = (value: any) => {
        setPagination(1)
        setTypeEventIdFilter(value)
    }
    const onChangeNameRecipient = (value: any) => {
        setPagination(1)
        setNameRecipientFilter(value)
    }
    const onChangeRoleRecipient = (value: any) => {
        setPagination(1)
        setRoleRecipientFilter(value)
    }
    const onChangeDate = (value: any) => {
        setPagination(1)
        setDateFilter(value)
    }

    const changePage = (step: number) => () => {
        setPagination((prev) => Math.max(1, prev + step))
    }

    useMount(() =>
        fetchTypes({
            onSuccess(data) {
                const eventOptions = data.map((item: any) => {
                    return {
                        id: item.id,
                        value: item.id,
                        text: item.name,
                    }
                })
                setEventTypes({
                    options: [...eventOptions],
                    dictionary: eventOptions.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text })),
                })
            },
        }),
    )

    const loadEvents = useCallback(
        async ({
            pagination,
            dateFilter,
            typeEventIdFilter,
            nameCreatorFilter,
            roleCreatorFilter,
            nameRecipientFilter,
            roleRecipientFilter,
            pageSize,
        }: ILoadEvents) => {
            fetchEvents({
                body: {
                    limit: pageSize,
                    offset: (pagination - 1) * pageSize,
                    filters: {
                        event_date_from: dateFilter
                            ? moment(dateFilter[0]).startOf('day')
                            : undefined,
                        event_date_to: dateFilter ? moment(dateFilter[1]).endOf('day') : undefined,
                        type_event_ids: typeEventIdFilter,
                        name_creator: nameCreatorFilter,
                        role_creator: roleCreatorFilter,
                        name_recipient: nameRecipientFilter,
                        role_recipient: roleRecipientFilter,
                    },
                },
            })
        },
        [],
    )
    const events = result?.events
    // const total = result?.total
    const [isLastPage, setIsLastPage] = useState(
        result?.is_last_page ? result?.is_last_page : false,
    )

    const loadEventsDebounced = useCallback(debounce(loadEvents, 300), [])

    useEffect(() => {
        loadEventsDebounced({
            pagination,
            nameCreatorFilter,
            roleCreatorFilter,
            typeEventIdFilter,
            nameRecipientFilter,
            dateFilter,
            roleRecipientFilter,
            pageSize,
        })
    }, [
        pagination,
        pageSize,
        nameCreatorFilter,
        roleCreatorFilter,
        typeEventIdFilter,
        nameRecipientFilter,
        dateFilter,
        roleRecipientFilter,
    ])

    const getDataSource = (): IData[] =>
        events?.map(
            (item: {
                id: string
                ts_event: any
                type_event_id: number
                uid_creator: string
                name_creator: string
                role_creator: number
                uid_recipient: number
                name_recipient: string
                role_recipient: number
            }) => {
                const creatorRole = userRoleMap.get(item.role_creator)!
                const recipientRole =
                    item.role_recipient == 0
                        ? 'Не зарегистрирован'
                        : userRoleMap.get(item.role_recipient)!

                return {
                    key: item.id,
                    id: item.id,
                    date: item.ts_event,
                    eventId: item.type_event_id,
                    eventType: eventTypes.dictionary[item.type_event_id],
                    creatorRole: creatorRole,
                    creatorName: item.name_creator,
                    recipientName: item.name_recipient,
                    recipientRole: recipientRole,
                }
            },
        )

    const changePageSize = (value: string) => {
        const numericValue = parseInt(value)
        if (numericValue) {
            setPageSize(numericValue)
            setPagination(1)
        }
    }
    const [openedDataSource, setOpenedDataSource] = useState(getDataSource())

    useEffect(() => {
        void loadEvents({
            pagination,
            pageSize,
            nameCreatorFilter,
            roleCreatorFilter,
            typeEventIdFilter,
            nameRecipientFilter,
            dateFilter,
            roleRecipientFilter,
        })
    }, [pagination, pageSize])

    useEffect(() => {
        if (!result) {
            return
        }
        setIsLastPage(result.is_last_page)
        setOpenedDataSource(getDataSource())
    }, [result])

    return (
        <div className={style.opened}>
            <Filter
                eventTypesOptions={eventTypes.options}
                onChangeEventId={onChangeEventId}
                onChangeNameCreator={onChangeNameCreator}
                onChangeRoleCreator={onChangeRoleCreator}
                onChangeNameRecipient={onChangeNameRecipient}
                onChangeDate={onChangeDate}
            />
            <MoreFilters onChangeRoleRecipient={onChangeRoleRecipient} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Spin spinning={isLoading || isLoadingTypes}>
                        <Table
                            dataSource={openedDataSource}
                            columns={columns}
                            pagination={
                                false
                                /*{
                                position: ['bottomLeft'],
                                onChange: (page, page_size) => {
                                    setPagination(page)
                                    setPageSize(page_size)
                                },
                                total: total,
                                pageSize: pageSize,
                                current: pagination,
                                showSizeChanger: true,
                            }*/
                            }
                            onRow={(record) => ({
                                onClick: () => {
                                    if (isAddedType(record.eventId)) {
                                        navigate('/audit/' + record.key, {
                                            state: { eventData: record },
                                        })
                                    }
                                },
                            })}
                        />
                        <div className={style.paginationWrap}>
                            <button
                                className={style.paginationControl}
                                onClick={changePage(-1)}
                                disabled={pagination === 1}
                            >
                                <LeftOutlined />
                            </button>
                            <button
                                className={style.paginationControl}
                                onClick={changePage(1)}
                                disabled={isLastPage || !result?.events}
                            >
                                <RightOutlined />
                            </button>
                            <Select
                                defaultValue={{ value: 10, label: <span>10 / стр. </span> }}
                                onChange={changePageSize}
                                options={PAGE_SIZE_OPTIONS}
                            />
                        </div>
                    </Spin>
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
